<template>
  <v-container id="data-tables-view" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-col class="text-right">
      <app-btn class="mx-2" @click="dialogBulkDeviceUpdate = true">
        <v-icon>mdi-view-grid-plus</v-icon> Set Device Settings
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-cellphone-cog"
      icon-small
      color="primary"
      title="Mobile Status"
    >
      <v-card-text>
        <v-row>
          <v-col class="text-left">
            <v-switch v-model="singleExpand" label="Single expand" />
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Search records"
              single-line
              style="max-width: 250px"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3" />
        <v-data-table
          :headers="headers"
          :items="mobileStatuses"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="totalDevices"
          :loading="loading"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-select
          v-model="selectedDevices"
          :show-expand="isExpand"
          :items-per-page="20"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15, 20, 30, 40, 50],
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteLocalData" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Delete Local Records</v-card-title
                >
                <v-card-text align="center">
                  <app-btn
                    class="ma-2"
                    @click="deleteFailedPickingProductsFromLocalDb()"
                  >
                    <v-icon>mdi-delete</v-icon> Delete picking failed Products
                  </app-btn>
                  <app-btn
                    class="mx-2"
                    @click="deleteOrderProductsFromLocalDb()"
                  >
                    <v-icon>mdi-delete</v-icon> Delete order products & Reset
                    Login
                  </app-btn>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDeleteLocalData = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogMessages" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Messages</span>
                </v-card-title>
                <v-card-text>
                  <v-row class="mx-2 mt-2">
                    <v-col md="10">
                      <v-text-field v-model="message" label="Message" />
                    </v-col>
                    <v-col md="2" class="mt-2">
                      <v-btn color="success" @click="addMessage">
                        {{ btn }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card
                    v-for="message in messages"
                    :key="message.id"
                    color="#E8F5E9"
                    class="ma-1 pa-1"
                  >
                    <v-row class="ma-1">
                      <v-col md="10">
                        {{ message.description }}
                      </v-col>
                      <v-col md="2">
                        <v-icon
                          small
                          color="info"
                          class="mr-2"
                          @click="editMessage(message)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          color="error"
                          @click="deleteMessage(message)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogMessages = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRequiredVersion" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Required Version</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentRequiredVersion"
                        :items="appVersions"
                        item-text="fileName"
                        item-value="fileName"
                        label="Required Version"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogRequiredVersion = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateRequiredVersion"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogFtpProfile" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">FTP Profile</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentFtpProfile"
                        :items="ftpProfiles"
                        item-text="name"
                        item-value="id"
                        label="FTP Profile"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFtpProfile = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateFtpProfile">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCurrentServer" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Current Server</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentServer"
                        :items="serverUrls"
                        item-text="value"
                        item-value="id"
                        label="Current Server Url"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCurrentServer = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateServerUrl">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogBulkDeviceUpdate" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Device Settings</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentRequiredVersion"
                          :items="appVersions"
                          item-text="fileName"
                          item-value="fileName"
                          label="Required Version"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentFtpProfile"
                          :items="ftpProfiles"
                          item-text="name"
                          item-value="id"
                          label="FTP Profile"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentServer"
                          :items="serverUrls"
                          item-text="value"
                          item-value="id"
                          label="Current Server Url"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <div class="ml-2 mt-2">Require Download:</div>
                          <v-icon
                            v-if="currentRequiredDownload"
                            color="success"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-else
                            color="error"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogBulkDeviceUpdate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="bulkDeviceUpdate">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img
              v-if="item.deviceModel == 'TC21'"
              src="../assets/TC21&26.jpg"
              width="100"
            />
            <img
              v-else-if="item.deviceModel == 'MC33'"
              src="../assets/MC33.png"
              width="100"
            />
            <div v-else class="text-center">
              <img src="../assets/defaultDevice.png" width="100" />
            </div>
          </template>
          <template v-slot:[`item.messages`]="{ item }">
            <v-chip
              class="ma-2"
              text-color="white"
              color="success"
              @click="openMessageDialog(item.id, item.messages)"
            >
              {{ item.messages.length }}
            </v-chip>
          </template>
          <template v-slot:[`item.deleteLocalData`]="{ item }">
            <v-icon color="error" @click="openDialogDeleteLocalData(item)"
              >mdi-delete</v-icon
            >
          </template>
          <template v-slot:[`item.requiredVersion`]="{ item }">
            <v-chip
              color="success"
              dark
              @click="openDialogRequiredVersion(item)"
            >
              {{ item.requiredVersion }}
            </v-chip>
          </template>
          <template v-slot:[`item.requireDownload`]="{ item }">
            <v-icon
              v-if="item.requireDownload"
              color="success"
              large
              @click="updateRequireDownload(item, false)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              color="error"
              large
              @click="updateRequireDownload(item, true)"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:[`item.ftpConfiguration`]="{ item }">
            <v-chip color="success" dark @click="openDialogFtpProfile(item)">
              {{ item.ftpConfiguration ? item.ftpConfiguration.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.currentServer`]="{ item }">
            <v-chip color="success" dark @click="openDialogCurrentServer(item)">
              {{ item.currentServer ? item.currentServer.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.lastConnected`]="{ item }">
            <div>{{ timeFormat(item.lastConnected) }}</div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <app-tabs color="primary" vertical icons-and-text>
                <template v-for="tab in tabs">
                  <v-tab :key="tab">
                    {{ tab }}
                  </v-tab>
                  <v-tab-item :key="`tab-item-${tab}`" class="ma-2">
                    <div v-if="tab == 'status'">
                      <v-data-table
                        :headers="statusHeaders"
                        :items="item.statuses"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :footer-props="{
                          showFirstLastPage: true,
                        }"
                        class="elevation-2"
                      >
                        <template v-slot:[`item.lastConnected`]="{ item }">
                          <div>{{ timeFormat(item.lastConnected) }}</div>
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="tab == 'license'">
                      <v-data-table
                        :headers="licenseHeaders"
                        :items="item.licenses"
                        :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options': [5, 10, 15],
                        }"
                        class="elevation-2"
                      />
                    </div>
                    <div v-if="tab == 'locations'">
                      <v-data-table
                        :headers="locationHeaders"
                        :items="item.locations"
                        :sort-by.sync="locationSortBy"
                        :sort-desc.sync="sortDesc"
                        :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options': [5, 10, 15],
                        }"
                        class="elevation-2"
                      >
                        <template v-slot:[`item.time`]="{ item }">
                          <div>{{ timeFormat(item.time) }}</div>
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="tab == 'installed apps'">
                      <v-text-field
                        v-model="appSearch"
                        append-icon="mdi-magnify"
                        class="ml-auto"
                        hide-details
                        label="Search records"
                        single-line
                        style="max-width: 250px"
                      />

                      <v-divider class="mt-3" />
                      <v-data-table
                        :headers="appsHeaders"
                        :items="item.installedApps"
                        :search.sync="appSearch"
                        :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options': [5, 10, 15],
                        }"
                        class="elevation-2"
                      />
                    </div>
                  </v-tab-item>
                </template>
              </app-tabs>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import SettingsDataService from "../services/SettingsDataService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

var moment = require("moment");
export default {
  name: "Settings",
  components: { Loading },
  data: () => ({
    moment: moment,
    currentMacAddress: "",
    currentRequiredVersion: "",
    currentFtpProfile: "",
    dialogDeleteLocalData: false,
    currentUserName: "",
    dialogFtpProfile: false,
    dialogRequiredVersion: false,
    dialogCurrentServer: false,
    dialogBulkDeviceUpdate: false,
    sortBy: "lastConnected",
    locationSortBy: "time",
    isLoading: false,
    options: {},
    totalDevices: 0,
    loading: false,
    loadingSize: 128,
    loadingFullPage: false,
    sortDesc: true,
    appSearch: "",
    selectedDevices: [],
    headers: [
      {
        text: "Image",
        sortable: false,
        value: "image",
      },
      { text: "Model", sortable: false, value: "deviceModel" },
      { text: "MAC Address", sortable: false, value: "macAddress" },
      { text: "OS", sortable: false, value: "os" },
      { text: "App Version", sortable: false, value: "appVersion" },
      {
        text: "Required App Version",
        sortable: false,
        value: "requiredVersion",
      },
      { text: "Require Update", sortable: false, value: "requireDownload" },
      { text: "FTP Profile", sortable: false, value: "ftpConfiguration" },
      { text: "Current Server", sortable: false, value: "currentServer" },
      { text: "User", sortable: false, value: "username" },
      { text: "IP Address", sortable: false, value: "ipAddress" },
      { text: "Bay", sortable: false, value: "zone" },
      { text: "Signal Strength", sortable: false, value: "signalStrength" },
      { text: "BSSID", sortable: false, value: "bssid" },
      { text: "Link Speed", sortable: false, value: "linkSpeed" },
      { text: "ESSID", sortable: false, value: "essid" },
      { text: "Delete LocalData", sortable: false, value: "deleteLocalData" },
      { text: "Messages", sortable: false, value: "messages" },
      { text: "Last Connected", sortable: false, value: "lastConnected" },
    ],
    statusHeaders: [
      { text: "OS", value: "os" },
      { text: "App Version", value: "appVersion" },
      { text: "User", value: "username" },
      { text: "IP Address", value: "ipAddress" },
      { text: "Zone", value: "zone" },
      { text: "Signal Strength", value: "signalStrength" },
      { text: "BSSID", value: "bssid" },
      { text: "Link Speed", value: "linkSpeed" },
      { text: "ESSID", value: "essid" },
      { text: "Last Connected", value: "lastConnected" },
    ],
    locationHeaders: [
      { text: "Latitude", value: "latitude" },
      { text: "Longitude", value: "longitude" },
      { text: "Time", value: "time" },
    ],
    licenseHeaders: [{ text: "License Number", value: "licenseNumber" }],
    appsHeaders: [{ text: "applicationName", value: "applicationName" }],
    tabs: ["status", "installed apps", "license", "locations"],
    isExpand: true,
    expanded: [],
    appVersions: [],
    ftpProfiles: [],
    serverUrls: [],
    singleExpand: true,
    dialogMessages: false,
    btn: "",
    mobileStatuses: [],
    messages: [],
    message: "",
    currentMessage: "",
    currentDevice: "",
    currentServer: "",
    currentRequiredDownload: false,
    search: "",
    awaitingSearch: false,
  }),
  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.search);
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getDataFromApi(this.search);
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    getDataFromApi(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        Start: itemsPerPage * (page - 1),
        Length: itemsPerPage,
        Search: search,
        SortColumnName: "LastConnected",
        SortDirection: "desc",
      };
      // this.isLoading = true;
      SettingsDataService.getDevices(data)
        .then((response) => {
          // this.isLoading = false;
          this.totalDevices = response.data.totalDevices;
          this.mobileStatuses = response.data.devices;
          this.loading = false;
        })
        .catch(() => {
          // this.isLoading = false;
          this.loading = false;
        });
    },
    initialize() {
      SettingsDataService.getAppVersions().then((response) => {
        this.appVersions = response.data;
      });
      SettingsDataService.getAllFtpConfig().then((response) => {
        this.ftpProfiles = response.data;
      });
      SettingsDataService.getServerUrls().then((response) => {
        this.serverUrls = response.data;
      });
    },
    openMessageDialog(id, messages) {
      this.dialogMessages = true;
      this.btn = "Add";
      this.message = "";
      this.messages = messages;
      this.currentDevice = id;
    },
    editMessage(message) {
      this.btn = "Edit";
      this.message = message.description;
      this.currentMessage = message.id;
    },
    addMessage() {
      if (this.btn === "Edit") {
        var data = {
          id: this.currentMessage,
          mobileStatusId: this.currentDevice,
          description: this.message,
          active: 1,
        };
        SettingsDataService.update(data).then((response) => {
          var message = this.mobileStatuses.find(
            (x) => x.id === this.currentDevice
          ).messages;
          var index = message.findIndex((x) => x.id === this.currentMessage);
          this.messages.splice(index, 1, data);
        });
        this.btn = "Add";
      } else {
        var data = {
          mobileStatusId: this.currentDevice,
          description: this.message,
          active: 1,
        };
        SettingsDataService.create(data).then((response) => {
          this.mobileStatuses
            .find((x) => x.id == this.currentDevice)
            .messages.push(response.data);
        });
      }
      this.message = "";
    },
    deleteMessage(message) {
      SettingsDataService.delete(message.id).then((response) => {
        var message = this.mobileStatuses.find(
          (x) => x.id === this.currentDevice
        ).messages;
        var index = message.findIndex((x) => x.id === this.currentMessage);
        this.messages.splice(index, 1);
      });
    },
    getLicence(id) {
      var device = this.mobileStatuses.find((x) => x.id === id);
      if (device) {
        return device.licenses;
      }
    },
    timeFormat(time) {
      var dateTime = new Date(time);
      return this.moment(dateTime).add(10, "h").format("DD.MM.YYYY h:mm:ss a");
    },
    openDialogRequiredVersion(item) {
      this.currentRequiredVersion = "";
      this.currentMacAddress = item.macAddress;
      this.dialogRequiredVersion = true;
    },
    openDialogFtpProfile(item) {
      this.currentMacAddress = item.macAddress;
      this.dialogFtpProfile = true;
    },
    openDialogCurrentServer(item) {
      this.currentMacAddress = item.macAddress;
      this.dialogCurrentServer = true;
    },
    updateRequiredVersion() {
      var data = {
        macAddress: this.currentMacAddress,
        requiredVersion: this.currentRequiredVersion,
      };
      SettingsDataService.updateRequiredVersion(data).then((response) => {
        if (response.data.isSuccess) {
          const index = this.mobileStatuses.findIndex(
            (x) => x.macAddress == this.currentMacAddress
          );
          if (index > -1) {
            const device = this.mobileStatuses[index];
            device.requiredVersion = this.currentRequiredVersion;
            this.$set(this.mobileStatuses, index, device);
          }
          this.$toast.success(
            "Required status updated successfully.",
            "Success",
            {
              position: "topRight",
            }
          );
        }
      });
      this.dialogRequiredVersion = false;
    },
    updateRequireDownload(item, isRequired) {
      var data = {
        macAddress: item.macAddress,
        requireDownload: isRequired,
      };
      SettingsDataService.updateRequiredDownload(data).then((response) => {
        if (response.data.isSuccess) {
          const index = this.mobileStatuses.findIndex(
            (x) => x.macAddress == item.macAddress
          );
          if (index > -1) {
            const device = this.mobileStatuses[index];
            device.requireDownload = isRequired;
            this.$set(this.mobileStatuses, index, device);
          }
          this.$toast.success(
            "Required status updated successfully.",
            "Success",
            {
              position: "topRight",
            }
          );
        }
      });
    },
    updateFtpProfile() {
      var data = {
        macAddress: this.currentMacAddress,
        ftpConfigurationId: this.currentFtpProfile,
      };
      SettingsDataService.updateDeviceFtpConfigurations(data).then(
        (response) => {
          if (response.data.isSuccess) {
            this.$toast.success(
              "Required status updated successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
            this.getDataFromApi();
          }
        }
      );
      this.dialogFtpProfile = false;
    },
    updateServerUrl() {
      var data = {
        macAddress: this.currentMacAddress,
        settingId: this.currentServer,
      };
      SettingsDataService.updateCurrentServerUrl(data).then((response) => {
        if (response.data.isSuccess) {
          this.$toast.success("Server url updated successfully.", "Success", {
            position: "topRight",
          });
          this.getDataFromApi();
        }
        this.dialogCurrentServer = false;
      });
    },
    isRequiredDownload() {
      this.currentRequiredDownload = !this.currentRequiredDownload;
    },
    bulkDeviceUpdate() {
      var macAddressList = [];
      this.selectedDevices.forEach((x) => {
        macAddressList.push(x.macAddress);
      });
      var data = {
        macAddresses: macAddressList,
        settingId: this.currentServer,
        ftpConfigurationId: this.currentFtpProfile,
        requireDownload: this.currentRequiredDownload,
        requiredVersion: this.currentRequiredVersion,
      };
      SettingsDataService.updateDeviceConfigurations(data).then((response) => {
        if (response.data.isSuccess) {
          this.$toast.success("Server url updated successfully.", "Success", {
            position: "topRight",
          });
          this.getDataFromApi();
        }
      });
      this.dialogBulkDeviceUpdate = false;
    },
    openDialogDeleteLocalData(item) {
      this.currentUserName = item.username;
      this.dialogDeleteLocalData = true;
    },
    deleteOrderProductsFromLocalDb() {
      this.$http
        .get("/Device/DeleteOrderProducts", {
          params: { username: this.currentUserName },
        })
        .then((response) => {})
        .catch(() => {
          this.$toast.error("Something went wrong.", "Error", {
            position: "topRight",
          });
        });
    },
    deleteFailedPickingProductsFromLocalDb() {
      this.$http
        .get("/Device/DeletePickingFailedProducts", {
          params: { username: this.currentUserName },
        })
        .then((response) => {})
        .catch(() => {
          this.$toast.error("Something went wrong.", "Error", {
            position: "topRight",
          });
        });
    },
  },
};
</script>
